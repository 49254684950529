<template>
  <div
    class="container-top"
    ref="containerTop"
    :style="!isMobile ? { paddingBottom: '36px !important' } : {}"
    v-if="detail"
  >
    <div class="pc">
      <div class="container padding-top-60">
        <div class="product-wrapper">
          <!-- 상품정보 좌 -->
          <div>
            <div class="card-img" :style="imgField(detail.img, 'pc')">
              <div class="card-exts" v-if="exts.length > 0">
                <img v-for="(ext, idx) in exts" :key="`ext-${idx}`" :src="`/static/icon/ext_${ext}.svg`" />
              </div>
            </div>
          </div>
          <!-- 상세정보 우 -->
          <div>
            <div class="box-right">
              <div class="body5 sub3 unselect" @click="clickCategory">{{ detail.category2_name }}</div>
              <div class="flex-align" style="gap:4px">
                <div class="subtitle3 main">{{ detail.title }}</div>
                <div :class="`card-label ${detail.theme_version === 'beta' ? 'beta' : 'version'}`">
                  {{ detail.theme_version }}
                </div>
              </div>
              <div class="body3 padding-top-8">{{ detail.content }}</div>
              <div class="flex-align" style="gap:16px;margin-top:16px">
                <div class="flex-align" style="gap:4px">
                  <star-rating
                    :rating="detail.rate"
                    :readOnly="true"
                    active-color="#ff5722"
                    :star-size="12"
                    :increment="0.5"
                    :padding="1"
                    :star-points="[
                      20,
                      34,
                      30,
                      40,
                      31,
                      40,
                      32,
                      40,
                      33,
                      38,
                      33,
                      37,
                      31,
                      25,
                      39,
                      18,
                      40,
                      16,
                      40,
                      15,
                      39,
                      14,
                      38,
                      13,
                      27,
                      12,
                      22,
                      1,
                      21,
                      0,
                      20,
                      0,
                      19,
                      0,
                      18,
                      1,
                      13,
                      12,
                      2,
                      13,
                      1,
                      14,
                      0,
                      15,
                      0,
                      16,
                      1,
                      18,
                      9,
                      25,
                      7,
                      37,
                      7,
                      38,
                      8,
                      40,
                      9,
                      40,
                      10,
                      40,
                      20,
                      34,
                    ]"
                    :show-rating="false"
                  ></star-rating>
                  <div class="body4-medium primary unselect" @click="clickReview">
                    {{ detail.reviews_count | currencyNum }}개 후기
                  </div>
                </div>
                <div class="flex-align" style="gap:4px">
                  <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:14px" />
                  <div class="body4 sub3">조회수 {{ detail.visit | currencyNum }}</div>
                </div>
              </div>
              <div class="margin-top-20">
                <div class="body5 sub2 flex-align">
                  <div>12개월</div>
                  <div v-if="!detail.has_order" class="tag-black">
                    <div class="triangle"></div>
                    <div>👏 첫 구매 할인가</div>
                  </div>
                </div>
                <div class="h6 main margin-top-4">
                  <span v-if="detail.price.is_discount" class="primary" style="margin-right:8px"
                    >{{ detail.price.discount_rate }}%</span
                  >
                  {{ detail.price.sale_price | currencyNum
                  }}<span class="body1">원<span v-if="detail.price.is_subscription">/월~</span></span>
                </div>
              </div>
              <!-- 미리보기 버튼 -->
              <div class="button-group-preview" v-if="detail.preview_url">
                <button class="button is-primary-light2" @click="showMobilePreview">
                  <img
                    src="/static/icon/u_mobile-android-alt.svg"
                    alt=""
                    class="unselect"
                    style="width:20px;height:20px"
                  />
                  <span class="body2-medium margin-left-12">모바일 미리보기</span>
                </button>
                <a :href="detail.preview_url" target="_blank">
                  <button class="button is-primary-light2">
                    <img src="/static/icon/fi_monitor.svg" alt="" class="unselect" style="width:20px;height:20px" />
                    <span class="body2-medium margin-left-12">데스크톱 미리보기</span>
                  </button>
                </a>
              </div>
              <trial-button :product="detail"></trial-button>
            </div>
            <div class="box-right margin-top-24" style="padding:20px 32px">
              <div class="flex-between" style="gap:16px">
                <img :src="detail.partner.img" alt="" class="img-partner" />
                <div class="body2-medium main text-left" style="flex:1">{{ detail.partner.name }}</div>
                <div class="body3 sub2">
                  {{ category_name }} <span class="primary">{{ detail.partner.product_count | currencyNum }}개</span>
                </div>
              </div>
              <div class="border-top-gray-3"></div>
              <div class="unselect flex-align" style="gap:6px" @click="$router.push('/inquiry_reg?type_id=20')">
                <img src="/static/icon/fi_sale.svg" />
                <div class="body5 primary">플랫폼파이에서 판매하고 싶으신가요?</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 상품 상세 -->
        <!-- 관련 상품 -->
        <block-related-product
          v-if="detail.related_products.length > 0"
          :isTheme="true"
          :products="detail.related_products"
        ></block-related-product>
        <!-- 탭 -->
        <tab ref="tab" :menu="tabs" :style="`margin-top:${detail.related_products.length > 0 ? '40' : '100'}px`">
          <template v-slot:tab0>
            <div v-if="detail.imgs && detail.imgs.length > 0" style="margin-top:64px;padding:0 75px">
              <div class="content-slide" v-if="detail.imgs">
                <slick ref="slick" :options="slickOptions">
                  <div v-for="(img, idx) in detail.imgs" :key="'slide-' + idx" class="slick-img-outer">
                    <img :src="img" alt="" class="slick-img" :style="slickImg" />
                  </div>
                </slick>
              </div>
            </div>
            <div class="sun-editor-editable content column is-12" id="anchor0" v-html="detail.info"></div>
          </template>
          <template v-slot:tab1>
            <div class="content column is-12" style="margin-top:64px">
              <product-review></product-review>
            </div>
          </template>
          <template v-slot:tab2>
            <div class="content column is-12" style="margin-top:64px">
              <product-inquiry></product-inquiry>
            </div>
          </template>
        </tab>
        <!-- 다른 테마 살펴보기 -->
        <div class="margin-top-80" style="display: flex; flex-direction: column; gap:16px; padding-bottom: 40px;">
          <div class="h7 main">
            다른 테마 살펴보기
          </div>
          <div class="grid-product-wrap">
            <card-product v-for="theme in themes" :key="`theme-${theme.id}`" :card="theme"></card-product>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!vTop && !isMobile" class="bottom-btn-container">
      <div class="bottom-btn-wrapper">
        <div>5분만에 플랫폼 개설! 무료로 먼저 체험해 보시고 결정하세요.</div>
        <button class="bottom-fixed-btn button is-primary body2-bold" @click="clickOrder">무료체험 시작하기</button>
      </div>
    </div>
    <div v-else-if="vTop && !isMobile" style="height:84px"></div>
    <div class="mobile">
      <div class="card-img" :style="imgField(detail.img, 'mobile')">
        <div class="card-exts" v-if="exts.length > 0">
          <img v-for="(ext, idx) in exts.slice(0, 5)" :key="`ext-${idx}`" :src="`/static/icon/ext_${ext}.svg`" />
        </div>
      </div>
      <div class="container padding-top-16">
        <!-- 파트너 -->
        <div class="body6 sub3">{{ detail.category2_name }}</div>
        <div class="flex-align" style="gap:4px">
          <div class="subtitle5 main" style="margin-top:6px">{{ detail.title }}</div>
          <div :class="`card-label ${detail.theme_version === 'beta' ? 'beta' : 'version'}`" style="margin-top:6px">
            {{ detail.theme_version }}
          </div>
        </div>
        <div class="padding-top-8 sub2">{{ detail.content }}</div>

        <div class="flex-align" style="gap:16px;margin-top:16px">
          <div class="flex-align" style="gap:4px">
            <star-rating
              :rating="detail.rate"
              :readOnly="true"
              active-color="#ff5722"
              :star-size="12"
              :increment="0.5"
              :padding="1"
              :star-points="[
                20,
                34,
                30,
                40,
                31,
                40,
                32,
                40,
                33,
                38,
                33,
                37,
                31,
                25,
                39,
                18,
                40,
                16,
                40,
                15,
                39,
                14,
                38,
                13,
                27,
                12,
                22,
                1,
                21,
                0,
                20,
                0,
                19,
                0,
                18,
                1,
                13,
                12,
                2,
                13,
                1,
                14,
                0,
                15,
                0,
                16,
                1,
                18,
                9,
                25,
                7,
                37,
                7,
                38,
                8,
                40,
                9,
                40,
                10,
                40,
                20,
                34,
              ]"
              :show-rating="false"
            ></star-rating>
            <div class="body5-medium primary unselect" @click="clickReview">
              {{ detail.reviews_count | currencyNum }}개 후기
            </div>
          </div>
          <div class="flex-align" style="gap:4px">
            <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:14px" />
            <div class="body5 sub3">조회수 {{ detail.visit | currencyNum }}</div>
          </div>
        </div>
        <div class="margin-top-8">
          <div class="body5 sub2 flex-align">
            <div>12개월</div>
            <div v-if="!detail.has_order" class="tag-black">
              <div class="triangle"></div>
              <div>👏 첫 구매 할인가</div>
            </div>
          </div>
          <div class="body0-bold main  margin-top-4">
            <span v-if="detail.price.is_discount" class="primary" style="margin-right:8px"
              >{{ detail.price.discount_rate }}%</span
            >
            {{ detail.price.sale_price | currencyNum
            }}<span class="body3">원<span v-if="detail.price.is_subscription">/월~</span></span>
          </div>
        </div>

        <a class="mobile-preview" :href="detail.preview_url" target="_blank" v-if="detail.preview_url">
          <button class="button is-primary-light2 margin-top-24 body4" style="width:100%;height:40px">미리보기</button>
        </a>
      </div>
      <div class="lp-divider-gray3 padding-top-24"></div>
      <div class="container padding-top-16">
        <trial-button :product="detail"></trial-button>

        <div class="box-right margin-top-24">
          <div class="flex-between" style="gap:16px">
            <img :src="detail.partner.img" alt="" class="img-partner" />
            <div class="body2-medium main text-left" style="flex:1">{{ detail.partner.name }}</div>
            <div class="body3 sub2">
              {{ category_name }} <span class="primary">{{ detail.partner.product_count | currencyNum }}개</span>
            </div>
          </div>
          <div class="border-top-gray-3"></div>
          <div class="unselect flex-align" style="gap:6px" @click="$router.push('/inquiry_reg?type_id=20')">
            <img src="/static/icon/fi_sale.svg" />
            <div class="body5 primary">플랫폼파이에서 판매하고 싶으신가요?</div>
          </div>
        </div>
      </div>

      <!-- 관련 상품 -->
      <block-related-product
        v-if="detail.related_products.length > 0"
        :isTheme="true"
        :products="detail.related_products"
      ></block-related-product>
      <!-- 상품 상세-->
      <div>
        <div class="lp-divider-gray3-12 margin-top-12"></div>
        <!-- 탭 -->
        <tab ref="mtab" :menu="tabs" anchor="mtabs" anchorTop="-59px">
          <template v-slot:tab0>
            <div v-if="detail.imgs && detail.imgs.length > 0" class="content column is-12">
              <div class="content-slide" v-if="detail.imgs">
                <scroll-wrapper>
                  <div class="flex-align">
                    <img
                      v-for="(img, idx) in detail.imgs"
                      :key="'slide-' + idx"
                      :src="img"
                      alt=""
                      class="slick-img"
                      style="width:260px;height:385px;margin:0 6px"
                    />
                  </div>
                </scroll-wrapper>
              </div>
            </div>
            <div v-html="detail.info" class="sun-editor-editable padding-left-16 padding-right-16"></div>
          </template>
          <template v-slot:tab1>
            <product-review></product-review>
          </template>
          <template v-slot:tab2>
            <product-inquiry></product-inquiry>
          </template>
        </tab>
        <!-- 다른 테마 살펴보기 -->
        <div class="themes-wrapper-mobile">
          <div class="h8 main">다른 테마 살펴보기</div>
          <div id="container-1" style="margin-top:16px">
            <card-product
              style="margin-top:20px"
              device="mobile"
              isTheme
              v-for="theme in themes"
              :key="`theme-${theme.id}`"
              :card="theme"
            ></card-product>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-show="vTop && !isMobile" class="fixed-bottom-btn-container">
        <div class="fixed-bottom-btn-wrapper">
          <div>5분만에 플랫폼 개설! 무료로 먼저 체험해 보시고 결정하세요.</div>
          <button class="bottom-fixed-btn button is-primary body2-bold" @click="clickOrder">무료체험 시작하기</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import UserAPIMixin from '../../mixins/UserAPIMixin';
import ProductInquiry from './ProductInquiry';
import Tab from '../module/Tab';
import ScrollWrapper from '../module/ScrollWrapper';
import TrialButton from '../component/TrialButton';
import BlockRelatedProduct from '../component/BlockRelatedProduct';
import ProductReview from './ProductReview';
import Slick from 'vue-slick';
import StarRating from 'vue-star-rating/src/star-rating';
import CardProduct from '../component/CardProduct';

export default {
  name: 'ThemeDetail',
  mixins: [UserAPIMixin],
  components: {
    ProductReview,
    BlockRelatedProduct,
    TrialButton,
    ScrollWrapper,
    Tab,
    ProductInquiry,
    Slick,
    StarRating,
    CardProduct,
  },
  data() {
    return {
      themes: [],
      products: {
        call: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0,
        },
        reservation: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0,
        },
        booking: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0,
        },
        delivery: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0,
        },
        estimate: {
          id: 0,
          sale_price: 600000,
          discount_rate: 20,
          rate: 4.0,
          visit: 0,
        },
      },
      vTop: false,
      order_product: undefined,
      tabs: [
        { id: 0, name: '서비스 안내' },
        { id: 1, name: '후기' },
        { id: 2, name: '이용문의' },
      ],
      // 상세 상품
      detail: undefined,
      slickOptions: {
        centerMode: false,
        variableWidth: false,
        infinite: true,
        autoplay: false,
        centerPadding: '20px',
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 600,
        prevArrow:
          '<div class="arrow-left"><img class="arrow-img" src="/static/icon/fi_chevron-left.svg" @click="prev"></div>',
        nextArrow:
          '<div class="arrow-right"><img class="arrow-img" src="/static/icon/fi_chevron-right.svg" @click="next"></div>',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              autoplay: true,
              slidesToShow: 1,
              centerMode: true,
              dots: false,
              arrows: false,
            },
          },
        ],
      },
    };
  },
  watch: {
    $route(n) {
      if (n.query.id) {
        this.getProductDetail();
        this.getThemeProduct(n.query.id);
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.getProductDetail();
      this.getThemeProduct(this.$route.query.id);
      if (this.isMobile) {
        this.slickOptions.variableWidth = false;
        this.slickOptions.centerMode = false;
        this.slickOptions.autoplay = true;
      }
    }
  },
  beforeDestroy() {
    //document.getElementById('ch-plugin').style.display = '';
    window.removeEventListener('scroll', this.updateVTop);
  },
  mounted() {
    /*setTimeout(() => {
        document.getElementById('ch-plugin').style.display = 'none';
      }, 1000);*/
    this.updateVTop(); // 초기 스크롤 위치에 따라 vTop 설정
    window.addEventListener('scroll', this.updateVTop);
  },
  computed: {
    exts() {
      return this.detail && this.detail.params
        ? this.detail.params.find((i) => i.name === '파일 종류')
          ? this.detail.params
              .find((i) => i.name === '파일 종류')
              .choice_items.filter(
                (i) => this.detail.params.find((i) => i.name === '파일 종류').value_select.indexOf(i.id) > -1
              )
              .map((i) => i.name.toLowerCase())
          : []
        : [];
    },
    category_name() {
      if (this.detail) {
        if (this.detail.category2_name.indexOf('테마') > -1) {
          return '테마';
        } else if (this.detail.category2_name.indexOf('스킨') > -1) {
          return '스킨';
        } else {
          return '템플릿';
        }
      } else {
        return '';
      }
    },
    slickImg() {
      if (window.innerWidth > 500) {
        let width = 318;
        let height = 483;
        let margin = '0 6px';
        return {
          width: width + 'px',
          height: height + 'px',
          margin: margin,
          borderRadius: '8px',
        };
      } else {
        let width = window.innerWidth - 24.5;
        let height = width * 1.31;
        let padding = '0 6px';
        return {
          width: width + 'px',
          height: height + 'px',
          padding: padding,
          borderRadius: '8px',
        };
      }
    },
    contentSlideStyle() {
      if (window.innerWidth < 500) {
        return {
          width: '100%',
          height: this.slickImg.height,
        };
      } else {
        return {
          width: '1106px',
          height: this.slickImg.height,
        };
      }
    },
  },
  methods: {
    init() {
      this.order_product = {
        product_id: this.$route.query.id,
        quantity: 1,
        discount_price: 0,
        product_price: 0,
        total_price: 0,
        product: this.cloneItem(this.detail),
        order_product_option: [],
        required_option_price: 0,
        date_unit: 0,
      };
    },
    getThemeProduct(id) {
      this.$axios
        .get('user/0/mapping/product', {
          params: {
            category: 59,
            fields:
              'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,rate,visit,params,tags',
            ordering: 'priority',
          },
        })
        .then((res) => {
          this.themes = res.data.data.filter((i) => i.id !== Number(id));
          res.data.data.forEach((i) => {
            let key = i.params.find((p) => p.name === 'key').value;
            let obj = this.products[key];
            obj.id = i.id;
            obj.sale_price = i.price.sale_price;
            obj.discount_rate = i.price.discount_rate;
            obj.rate = i.rate;
            obj.visit = i.visit;
            obj.partner = i.partner;
            obj.img = i.img;
            obj.name = i.name;
            obj.tags = i.tags;
          });
        });
    },
    clickOrder() {
      this.$store.commit('setBasket', this.order_product);
      if (this.isLogin) {
        //this.routerPush('/trial_account');
        this.routerPush('/service_survey');
      } else {
        this.$store.commit('setPrevPath', this.$route.fullPath);
        this.$store.commit('setTrial', true);
        this.toast('로그인 후 진행 할 수 있습니다.');
        this.$router.replace('/signup');
      }
    },
    updateVTop() {
      const containerTop = this.$refs.containerTop;
      if (containerTop) {
        const containerTopHeight = containerTop.offsetHeight - 911;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        // 스크롤 위치가 .container-top의 높이보다 작거나 스크롤 위치가 300px 이상이면 vTop을 true로 설정
        this.vTop = scrollTop < containerTopHeight && scrollTop > 300;
      }
    },
    clickCategory() {
      let id = {
        59: 1,
        60: 2,
        61: 3,
      }[this.detail.category2];
      this.routerPush(`/theme_market?tab=${id}`);
    },
    clickReview() {
      if (window.innerWidth < 1024) {
        this.$refs.mtab.selectedItem = 1;
        location.replace('#mtabs');
      } else {
        this.$refs.tab.selectedItem = 1;
        location.replace('#tabs');
      }
    },
    showMobilePreview() {
      window.name = 'preview_parent';
      let popup = window.open(
        '',
        'previewPopup',
        'width=360, height=640, top=300, left=300, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=yes, scrollbar=no'
      );
      popup.location.href = this.detail.preview_url;
    },
    preview(device) {
      console.log(device);
      location.href = this.detail.preview_url;
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
    // 상품 상세
    getProductDetail() {
      let request = this.isLogin ? this.request.user : this.$axios;
      this.$store.commit('setLoading', true);
      request.get(`${this.isLogin ? '' : 'public/'}launchpack/v1/theme/${this.$route.query.id}`).then(async (res) => {
        if (res.status === 200) {
          this.$store.commit('setLoading', false);
          if (res.data.preview_url && res.data.preview_url.indexOf('demo') === -1) {
            let key = res.data.preview_url.split('.')[0].replace('https://', '');
            res.data.preview_url += `/home?demo_test=${key}`;
          }

          //res.data.img = res.data.imgs[0];
          // 대표이미지 제외
          //res.data.imgs = res.data.imgs.splice(1);
          // 슬라이드쇼 반복을 위해서 concat
          // res.data.imgs = res.data.imgs.concat(res.data.imgs);
          let detail = res.data;
          if (!detail.service_use_price) {
            detail.service_use_price = {
              id: 1,
              name: '서비스 운영 이용료',
              desc: null,
              price: 300000,
            };
          }
          await this.setServiceUsePrice(detail);
          this.detail = detail;

          this.init();
          if (this.$route.query.trial) {
            this.clickOrder();
          }
        }
      });
    },
    async setServiceUsePrice(detail) {
      let pack_name = '';
      let item = this.findItem(detail.params, 'name', '주문전환');
      if (item) {
        if (item.value_select && item.value_select.length > 0) {
          let pack_item = this.findItem(item.choice_items, 'id', item.value_select[0]);
          if (pack_item) {
            pack_name = pack_item.name;
          }
        }
        let query = '';
        if (pack_name) {
          query = `?pack=${this.convertPack(pack_name)}`;
        }
        await this.$axios.get(`public/launchpack/service_use_price/info${query}`).then((res) => {
          detail.service_use_price = res.data;
        });
      }
    },
    convertPack(name) {
      switch (name) {
        case '':
          return '';
        case '전화연결':
          return 'call';
        case '아웃링크':
          return 'outlink';
        case '상품배송주문':
          return 'delivery';
        case '모임신청':
          return 'meet';
        case '상담신청':
          return 'counsel';
        case '견적요청':
          return 'estimate';
        case '사용권':
          return 'license';
        case '컨텐츠다운로드주문':
          return 'commerce';
        case '숙박예약':
          return 'booking';
        case '서비스예약':
          return 'reservation';
      }
    },
    imgField(img, device) {
      let style = {
        backgroundImage: `url(${this.image(img)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
      if (device === 'mobile') {
        style.height = ((window.innerWidth * 3) / 4).toString() + 'px';
      }
      return style;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.pc
  .product-wrapper
    display grid
    grid-gap 0 72px
    grid-template-columns repeat(2, 1fr)

  .card-img
    position relative
    width 516px
    height 387px
    border-radius 8px
    .card-exts
      position absolute
      border-radius 6px
      top 8px
      right 8px
      z-index 1
      padding 7px 10px
      background-color $main
      display flex
      align-items center
      gap 8px

  .box-right
    border 1px solid $gray2
    padding 32px
    border-radius 12px

  .img-partner
    width 40px
    height 40px
    border-radius 100%


.mobile
  .card-img
    position relative
    width 100%
    border 1px solid $gray3
    .card-exts
      position absolute
      border-radius 2px
      top 4px
      right 4px
      z-index 1
      padding 2px 4px
      background-color $main
      display flex
      align-items center
      gap 4px

  .box-right
    border 1px solid $gray2
    padding 12px
    border-radius 6px

  .img-partner
    width 32px
    height 32px
    border-radius 100%


.lp-divider-gray3
  border-bottom 1px solid $gray3
.lp-divider-gray3-12
  border-bottom 12px solid $gray3

.button-group-preview
  display flex
  margin-top 20px
  gap 12px

  a
  .button
    width 100%
    flex 1
    display flex
    align-items center

  img
    filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)

.border-top-gray-3
  width 100%
  border-top 1px solid $gray3
  margin 12px 0

.tag-black
  background-color #000000
  color #ffffff
  padding 2px 6px
  position relative
  margin-left 8px
  font-size 12px
  .triangle
    width 6px
    height 6px
    top calc(50% - 3px)
    left -3px
    transform matrix(0.71, 0.65, -0.77, 0.71, 0, 0)
    background-color #000
    position absolute

.fixed-bottom-btn-container
    position fixed
    bottom 0
    left 50%
    transform translateX(-50%)
    width 100%
    z-index 1000
    display flex
    align-items center
    justify-content center
    padding 32px 0
.bottom-btn-container
  display flex
  align-items center
  justify-content center
  width 100%

.fixed-bottom-btn-wrapper
  width 1000px
  display flex
  align-items center
  justify-content center
  gap 40px
  padding 16px 10px
  background-color white
  border-radius 8px
  box-shadow 0px 0px 10px 0px rgba(0, 0, 0, 0.15)

.bottom-btn-wrapper
  width 1000px
  display flex
  align-items center
  justify-content center
  gap 40px
  padding 16px 10px
  background-color white
  border-radius 8px
  box-shadow 0px 0px 10px 0px rgba(0, 0, 0, 0.15)

.bottom-fixed-btn
  width fit-content
  height 52px
  border-radius 50px
  background-color $primary
  font-weight 700
  color white
  display flex
  align-items center
  justify-content center
  padding 15px 40px

.grid-product-wrap
    display grid
    width 100%
    grid-template-columns repeat(4, 1fr)
    gap 24px

.themes-wrapper-mobile
  border-top 1px solid $gray2
  padding 24px 16px 16px
  margin-top 16px

.section
    padding-bottom 50px

@media (min-width:1025px)
    .section-11
      position relative
      background-color #FFF9F9
      height 610px
      display flex
      align-items center
      justify-content center
      overflow hidden

  // tablet
  @media (max-width:1024px)
    .pc
      display none
    .tablet
      display block
    .mobile
      display block

.card-label
  color white
  font-style italic
  font-size 12px
  font-weight 600
  border-radius 2px
  height 22px
  display flex
  align-items center
  justify-content center
.beta
  background-color #7B61FF
  padding 1px 7px 1px 6px
.version
  background linear-gradient(322deg, #F60 22.51%, #F43B00 100%)
  padding 1px 8px 1px 6px

.fade-enter-active, .fade-leave-active
  transition opacity 0.3s

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
  opacity 0
</style>

<style lang="stylus">
@import '~assets/css/lp_main'

@media (max-width: 1024px)
  .tablet-preview
    display block

  .mobile-preview
    display none

@media (max-width: 640px)
  .tablet-preview
    display none

  .mobile-preview
    display block
</style>
